<template>
  <div>

    <div class="flex flex-row space-x-4">

      <div class="text-sm w-full">
        <div class="" v-if="metadatas.length" style="">
          <div v-for="(metadata, i) in metadatas" class="w-full mb-2">
            <label for="value" class="w-full label">{{ $t(metadata.name) }}</label>
            
            <b-select
              v-if="metadata.type == 'select'"
              v-model="metadatas[i].value"
              :placeholder="metadata.placeholder"
              class="w-full"
              :required="metadata.required"
              expanded
            >
              <option v-for="option in metadata.data" :value="option['value']" :key="option['value']">
                {{ option['name'] }}
              </option>
            </b-select>

            <b-switch
              v-else-if="metadata.type == 'checkbox'"
              v-model="metadatas[i].value"
              :required="metadata.required"
            >{{ $t(metadata.value ? 'yes' : 'no') }}
            </b-switch>

            <treeselect 
              v-else-if="metadata.type == 'treeselect' && metadata.field == 'activity'"
              v-model="metadatas[i].value" 
              :close-on-select="true" 
              :options="metadata.data" 
              :required="metadata.required"
              @select="loadActivityFolders"
            />

            <treeselect 
              v-else-if="metadata.type == 'treeselect' && metadata.field != 'activity' && metadata.field != 'task_code'"
              v-model="metadatas[i].value" 
              :close-on-select="true" 
              :required="metadata.required"
              :options="metadata.data" 
            />

            <button v-else-if="metadata.field == 'task_code'" class="button w-full" v-on:click="showTasksModal">Configurar tareas</button>

            <v-input
              v-else
              v-model="metadatas[i].value"
              :placeholder="metadata.placeholder"
              class="w-full"
              :required="metadata.required"
            />

          </div>                        
        </div>
      </div>
    </div>

    <rule-config-task ref="rule_config_task" />

  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import RuleConfigTask from './rule-config-task'

export default {
  components: {
    Treeselect,
    RuleConfigTask
  },

  props: {
    projectRule: {type: Object, default: null },
    projectId: {type: Number},
    templates: {type: Array},
  },


  data: () => ({
    isModalActive: false,
    item: {},
    editItem: {},
    metadatas: [],
    statusList: [],
    activities: [],
    currentFolders: []
  }),

  mounted () {
    this.getStatusList()
    this.loadActivities()
  },

  watch:{
    projectRule(val) {
      this.reloadForm()
    },
  },

  methods: {

    async getStatusList() {
      let data = await axios.get('/api/v2/project/' + this.projectId + '/statuses');

      if (data.data && data.data.success) {
        this.statusList = data.data.statuses.map(status => ({
          name: status.name,
          value: status.id
        }));
      }
      
      this.reloadForm()
    },

    async reloadForm() {
      this.$refs.rule_config_task.data = []
      this.loadMetadata()
    },

    async showTasksModal() {
      this.$refs.rule_config_task.show(this.templates)
    },

    async loadActivities() {
      const { data } = await axios.get("/api/v2/activities", {'params' : { p : this.projectId, per_page: 999, s:1, skip_map: 1}});
      this.activities = data.activities.data.map(activity => {
        return {
          id: activity.planning_code,
          activityId: activity.id,
          label: activity.name,
          children: activity.children_activities.map(subactivity => {
            return {
              id: subactivity.planning_code,
              activityId: subactivity.id,
              label: subactivity.name
            }
          })
        }
      });
      this.reloadForm()
    },

    async loadActivityFolders(node) {
      const { data } = await axios.get("/api/v2/activity/"+node.activityId, {'params' : { with_folders: 1}});
      for (let i = 0; i < this.metadatas.length; i++) {
        if (this.metadatas[i].field == 'folder' && this.metadatas[i].type == 'treeselect') {
          this.metadatas[i].value = null
          this.currentFolders = this.getFolders(data.data.folders)
          this.metadatas[i].data = this.currentFolders
          return
        }
      }
    },

    getFolders(folders) {

      var map = {}, node, roots = [], i;

      for (i = 0; i < folders.length; i += 1) {
        map[folders[i].id] = i; // initialize the map
        folders[i].children = []; // initialize the children
      }

      for (i = 0; i < folders.length; i += 1) {
        node = folders[i];
        if (node.parent_id !== null) {
          folders[map[node.parent_id]].children.push({id: node.id, label: node.name, children: node.children});
        } else {
          roots.push({id: node.id, label: node.name, children: node.children});
        }
      }

      this.cleanEmptyChildrens(roots)

      return roots;
    },

    // limpia los nodos eliminando la key 'children' si está vacio
    cleanEmptyChildrens(tree) {

      for (let i = 0; i < tree.length; i += 1) {

        // Caso base 1. No tiene la key 'children', do nothing
        if (!tree[i].children)
          return true

        // Caso base 2. Tiene la key vacia, eliminarla
        if (tree[i].children && tree[i].children.length == 0) {
          delete tree[i].children
          return true;

        // tiene childrens, pues llamar recursivamente
        } else {
          this.cleanEmptyChildrens(tree[i].children)
        }
      }
      return true

    },

    loadMetadata() {
      this.metadatas = []
      if (this.projectRule) {
        switch (this.projectRule.code) {
          case 'sendMailCompletion':
            this.metadatas = [
              {
                name: 'Correos',
                field: 'emails',
                value: '',
                placeholder: 'Correos a los que enviar email',
                required: true
              },
              {
                name: 'Mensaje',
                field: 'message',
                value: '',
                placeholder: 'Cuerpo de mensaje',
                required: true
              },
              {
                name: 'Agregar documentos',
                field: 'with_documents',
                value: false,
                placeholder: 'Incluir documentos en correo (1,0)',
                required: false,
                type: 'checkbox',
              }
            ]              
            break;
          case 'periodicCreation':
            this.metadatas = [
              {
                name: 'Día-Mes',
                field: 'day_month',
                value: '',
                placeholder: '01-01',
                required: true
              },
              {
                name: 'Seleccione actividad',
                field: 'activity',
                value: null,
                placeholder: 'Seleccione actividad o subactividad',
                required: true,
                type: 'treeselect',
                data: this.activities
              },
              {
                name: 'Carpeta (Opcional)',
                field: 'folder',
                value: null,
                placeholder: 'carpeta para documentos',
                required: false,
                type: 'treeselect',
                data: []
              }
            ]              
            break;
          case 'uncompletedFlowLock':
            this.metadatas = [
              {
                name: 'Código de validación',
                field: 'template_code',
                value: '',
                placeholder: 'Código de plantilla de flujo de validación',
                required: true
              },
              {
                name: 'Código actividad (sin prefijos)',
                field: 'activity',
                value: '',
                placeholder: 'Código de actividad o subactividad',
                required: true
              }
            ]              
            break;
          case 'changeActivityStatusTaskCompletion':
            this.metadatas = [
              {
                name: 'Seleccione actividad',
                field: 'activity',
                value: null,
                placeholder: 'Seleccione actividad o subactividad',
                required: true,
                type: 'treeselect',
                data: this.activities
              },
              {
                name: 'Tarea',
                field: 'task_code',
                value: null,
                placeholder: 'Selecciona tarea',
                required: true,
                type: 'treeselect',
                data: []
              },
              {
                name: 'Estado de actividad',
                field: 'status',
                value: null,
                placeholder: 'Selecciona estado',
                required: true,
                type: 'select',
                data: this.statusList
              },
            ]
            break;
          case 'automaticFlowCreation':
            this.metadatas = [
              {
                name: 'Código de validación',
                field: 'template_code',
                value: '',
                placeholder: 'Código de plantilla de flujo de validación',
                required: true
              },
              {
                name: 'Código actividad (sin prefijos)',
                field: 'activity',
                value: '',
                placeholder: 'Código de actividad o subactividad',
                required: true
              },
              {
                name: 'Carpeta (Opcional)',
                field: 'folder',
                value: '',
                placeholder: 'Folder1/Folder2/...',
                required: false
              }
            ]
            break;
        
          default:
            break;
        }
      }
    },

    isCompleted() {
      if (this.metadatas.length == 0) {
        return false
      }
      return this.metadatas.every(m => {
        let isCompleted = true
        if (m.required) {
          if (m.field == 'task_code' && this.$refs.rule_config_task.data.every(t => {return !!t.taskSelected})) {
            isCompleted = true
          } else {
            isCompleted = !!m.value
          }
        }
        return isCompleted
      })
    },

    getForm() {
      let form = {}
      this.metadatas.map(m => {
        form[m.field] = m.value
      })
      form.tasks_selected = this.$refs.rule_config_task.data.map(t => {
        return {
          template_id: t.template.id,
          task: t.taskSelected
        }
      })
      return form
    }
  },

};
</script>
