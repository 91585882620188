<template>
    <div v-if="previewData.length">
        <div class="table-container">
            <b-table 
            :data="previewData" 
            :loading="isLoading" 
            class="text-xs import_xslx_table" 
            :sticky-header="true" 
            scrollable 
            striped>
                <b-table-column v-for="(header, index) in headers" :key="index" :field="header"
                    :label="$t(header)" header-class="align-middle bg-blue header_xlsx">
                    <template #default="props">
                        <template v-if="header === 'import_status'">
                            <span class="text-center">
                                <b-tooltip 
                                v-if="props.row.import_status == 'error'"
                                :label="generateTooltipLabel(props.row.status_error)" 
                                position="is-right"
                                size="is-large" 
                                multilined 
                                type="is-danger">
                                    <b-tag type="is-danger">{{$t(props.row.import_status)}}</b-tag>
                                </b-tooltip>
                                <b-tag v-else :type="statusIconTypes[props.row.import_status]">{{$t(props.row.import_status)}}</b-tag>
                            </span>
                        </template>
                        <template v-else-if="header === 'actions' && props.row.status_error.length">
                            <span @click="openModal(props.row, props.index)" style="cursor: pointer;">
                                <b-icon pack="fa" size="is-small" icon="edit" class="ml-2 mt-2"></b-icon>
                            </span>
                        </template>
                        <template v-else-if="header === 'skip'">
                            <div class="ml-2 mt-2">
                                <b-field>
                                    <b-checkbox v-model="props.row[header]" @input="() => $emit('row-changed')"></b-checkbox>
                                </b-field>
                            </div>
                        </template>
                        <template v-else>
                            {{ props.row[header] }}
                        </template>
                    </template>
                </b-table-column>
                <import-excel-modal ref="dataModalExcel" @saved="handleSavedChanges"></import-excel-modal>
            </b-table>
        </div>
    </div>
</template>

<script>
import * as XLSX from 'xlsx';
import axios from 'axios';
import ImportExcelModal from "./import-excel-modal.vue";

export default {
    components: {
        ImportExcelModal,
    },
    props: {
        file: {
            type: File,
            default: null,
        },
        projectId: {
            type: Number,
            required: true,
        },
        overwrite: {
            type: Boolean,
            default: false
        },
        entityType: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            previewData: [],
            isLoading: false,
            errors: [],
            selectedRowIndex: null,
            headers: [],
            statusIconTypes: {
                start: 'is-warning',
                validated: 'is-primary',
                error: 'is-danger',
                progress: 'is-light',
                imported: 'is-success'
            },
            dateFields: []
        };
    },

    methods: {
        generateTooltipLabel(errors) {
            return errors.map(error => this.$t(error.error, {data: error.data, '0': `${this.$t(error.field)} (${error.data})`})).join(', ');
        },

        async processFile() {
            if (!this.file) {
                return { success: false, message: 'No file selected' };
            }
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                this.isLoading = true;

                reader.onload = async (event) => {
                    const data = event.target.result;
                    try {
                        const workbook = XLSX.read(data, { type: 'binary' });
                        const sheetName = workbook.SheetNames[0];
                        const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1, defval: '' });
                        const headers = worksheet[0];
                        const rows = worksheet.slice(1);

                        // Filtrar las filas vacías
                        const filteredData = rows.filter(row => {
                            return row.some(value => value !== '' && value !== null && value !== undefined);
                        });

                        let fields = this.getFields()
                        let mappedData = filteredData.map((row) => {
                            let item = {
                                skip: false,
                                import_status: 'start',
                                status_error: '',
                                actions: ''
                            }

                            Object.entries(fields).map(field => {
                                if (this.dateFields.includes(field[0]) && typeof row[field[1]] === 'number' && this.isExcelDate(row[field[1]])) {
                                    row[field[1]] = XLSX.SSF.format('d/m/yyyy', row[field[1]]);
                                }
                                item[field[0]] = `${row[field[1]]}`
                            })

                            return item
                        });

                        this.previewData = mappedData;
                        this.getHeaders()
                        resolve({ success: true, message: 'File processed successfully', data: this.previewData });
                    } catch (error) {
                        console.error('Error reading XLSX data:', error);
                        reject({ success: false, message: 'Error reading XLSX data' });
                    } finally {
                        this.isLoading = false;
                    }
                };

                reader.onerror = (error) => {
                    this.isLoading = false;
                    console.error('Error reading file:', error);
                    reject({ success: false, message: 'Error reading file' });
                };

                reader.readAsBinaryString(this.file);
            });
        },

        openModal(row, index) {
            this.selectedRowIndex = index;
            this.$refs.dataModalExcel.show(row);
        },
        // Método para manejar los cambios guardados recibidos del modal
        handleSavedChanges(updatedRow) {
            if (this.selectedRowIndex !== null) {
                updatedRow.import_status = 'start'
                this.$set(this.previewData, this.selectedRowIndex, updatedRow);
                this.selectedRowIndex = null;
                this.$emit('row-changed', updatedRow);
            }
        },

        updateDataErrors(errors) {
            for (let i in errors) {
                this.previewData[parseInt(i)].import_status = 'error'
                this.previewData[parseInt(i)].status_error = errors[`${i}`]
            }
        },

        updateDataStatus(lastIndex, status) {
            for (let i = 0; i <= lastIndex; i++) {
                if (this.previewData[i].import_status != 'imported' && !this.previewData[i].skip) {
                    this.previewData[i].import_status = status
                }
            }
        },
        
        // Valida si un número es una fecha de Excel
        isExcelDate(value) {
            // Excel usa el 1 de enero de 1900 como base para las fechas
            const excelEpoch = new Date(1900, 0, 1);
            const daysFromEpoch = value - 1; // Ajusta el día base
            const excelDate = new Date(excelEpoch.getTime() + daysFromEpoch * 86400000);
            return !isNaN(excelDate.getTime());
        },

        getHeaders() {
            if (!this.previewData.length) {
                return;
            }
            this.headers = (Object.keys(this.previewData[0])).filter(item => item != 'status_error')
        },

        getFields() {
            switch (this.entityType) {
                case 'activities': 
                    this.dateFields = ['scheduled_start_date', 'scheduled_end_date', 'current_start_date', 'current_end_date', 'real_start_date', 'real_end_date']
                    switch (this.type) {
                        case 'simple':
                            return {
                                group_code: 0,
                                planning_code: 1,
                                new_planning_code: 2,
                                parent_activity_planning_code: 3,
                                name: 4,
                                in_planning: 5,
                                critical_path: 6,
                                scheduled_start_date: 7,
                                scheduled_end_date: 8,
                                current_start_date: 9,
                                current_end_date: 10,
                                real_start_date: 11,
                                real_end_date: 12,
                                progress: 13,
                                weight: 14,
                                email_manager: 15,
                                location_reference_code: 16,
                                participants: 17,
                                collaborators: 18,
                                tags: 19,
                                status: 20,
                                show_on_devices: 21,
                                description: 22,
                                document_codes: 23,
                                flow_codes: 24
                            }
                        case 'prelations':
                            return {
                                group_code: 0,
                                id_column: 1,
                                planning_code: 2,
                                new_planning_code: 3,
                                parent_activity_planning_code: 4,
                                name: 5,
                                in_planning: 6,
                                critical_path: 7,
                                scheduled_start_date: 8,
                                scheduled_end_date: 9,
                                current_start_date: 10,
                                current_end_date: 11,
                                predecessors: 12,
                                successors: 13,
                                real_start_date: 14,
                                real_end_date: 15,
                                progress: 16,
                                weight: 17,
                                email_manager: 18,
                                location_reference_code: 19,
                                participants: 20,
                                collaborators: 21,
                                tags: 22,
                                status: 23,
                                show_on_devices: 24,
                                description: 25,
                                document_codes: 26,
                                flow_codes: 27
                            }
                    }
                    break;
                case 'assignment': 
                    switch(this.type) {
                        case 'resources_bcs':
                            return {
                                activity: 0,
                                activity_name: 1,
                                measurement_code: 2,
                                measurement_name: 3,
                                measurement_unit_id: 4,
                                total_quantity: 5,
                                weight: 6,
                                unit_price: 7,
                                code_order: 8,
                                position: 9,
                            }
                    }
                    break;
            }
        }

    },
};
</script>

<style>
.table-container {
    overflow-x: auto;
}

.header_xlsx {
    background: #5577BB !important;
    vertical-align: middle !important;
    text-align: center !important;
}
.import_xslx_table > .table-container {
    height: 500px !important;
}
</style>
